import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { createInvite } from "../api/FileRequestAPI";
import { useOktaAuth } from "@okta/okta-react";
import Lightbox from "../components/Lightbox";

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";

type Log = {
  type: string;
  time: Date;
  body: string;
};

type LogTableProps = {
  wssURL: string;
  messages: Log[];
};

type ImageFile = {
  url: string;
  title: string;
};

const isValidPhone = (phone: string) => /^\d{10}$/.test(phone);

function FileRequest() {
  const { authState } = useOktaAuth();
  const [authToken, setAuthToken] = useState<string | undefined>("");
  const [idToken, setIdToken] = useState<string | undefined>("");

  const [name, setName] = useState("Patient");
  const [method] = useState("sms");
  const [address, setAddress] = useState("");
  const [inviteId, setInviteId] = useState("");
  const [wssURL, setWssURL] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<ImageFile[]>([]);
  const [isSending, setIsSending] = useState(false);
  const [showImageURL, setShowImageURL] = useState("");
  const [messages, setMessages] = useState<Log[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      const accessToken = authState?.accessToken?.accessToken;
      const idToken = authState?.idToken?.idToken;
      // console.log("authState", authState);
      // console.log("accessToken", accessToken);
      // console.log("idToken", idToken);
      setAuthToken(accessToken);
      setIdToken(idToken);
    }
  }, [authState]);

  useEffect(() => {
    if (!wssURL) return;

    ws.current = new WebSocket(wssURL);
    ws.current.onopen = () => {
      // send SMS
      if (ws.current) ws.current.send("sms");
      // console.log("ws opened");
    };
    ws.current.onclose = () => {
      console.log("ws closed");
    };
    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      // console.log("ws onmessage", message);
      if (message.type === "FileDownloadUrlCreated") {
        setUploadedFiles((currentFiles) => [
          ...currentFiles,
          { url: message.body, title: "File" + currentFiles.length + 1 },
        ]);
        // skip message from log
        return;
      }

      setMessages((currentMessages) => [message, ...currentMessages]);
    };
    const wsCurrent = ws.current;
    return () => {
      wsCurrent.close();
      console.log("ws closed");
    };
  }, [wssURL]);

  const sendRequest = async () => {
    try {
      setIsSending(true);
      const newInvite = await createInvite(
        name,
        method,
        address,
        authToken,
        idToken
      );
      setMessages((currentMessages) => [
        { type: "messageCreated", body: "Message created", time: new Date() },
        ...currentMessages,
      ]);
      // console.log("newInvite", newInvite);
      setInviteId(newInvite.inviteId);
      const wsUrl = `${newInvite.wssUrl}?inviteId=${newInvite.inviteId}`;
      // console.log("wsURL", wsUrl);
      setWssURL(wsUrl);
    } catch (ex) {
      console.error("sendRequestError", ex);
    } finally {
      setIsSending(false);
    }
  };

  const sendSMS = async () => {
    setIsSending(true);
    if (ws.current) ws.current.send("sms");
    setTimeout(() => {
      setIsSending(false);
    }, 2000);
  };

  const completeRequest = async () => {
    setName("");
    setInviteId("");
    setAddress("");
    setWssURL("");
    setMessages([]);
    setUploadedFiles([]);
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Card variant="outlined" sx={{ mb: 4, boxShadow: 1 }}>
        <CardContent>
          <Grid container>
            <Grid item xs={4}>
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 2 },
                }}
                noValidate
                autoComplete="off"
              >
                <h2>Secure File Request</h2>
                {/* <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  placeholder="John Doe"
                  helperText="Please enter recipient name"
                  focused
                  disabled={inviteId !== ""}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /> */}
                <TextField
                  id="outlined-basic"
                  label="Phone"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                  disabled={inviteId !== ""}
                  value={address}
                  helperText="Please enter recipient cell phone number"
                  onChange={(e) => setAddress(e.target.value)}
                />
                <br />
                {!inviteId ? (
                  <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    onClick={sendRequest}
                    disabled={isSending || !isValidPhone(address)}
                  >
                    {isSending ? "Processing ..." : "Send Request"}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      size="large"
                      disableElevation
                      disabled={isSending}
                      onClick={sendSMS}
                    >
                      {isSending ? "Processing ..." : "Resend SMS"}
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      disableElevation
                      onClick={() => setConfirmOpen(true)}
                    >
                      Complete
                    </Button>
                    <ConfirmationDialog
                      open={confirmOpen}
                      handleOK={() => {
                        completeRequest();
                        setConfirmOpen(false);
                      }}
                      handleCancel={() => setConfirmOpen(false)}
                    />
                  </>
                )}
                {messages.length > 0 && (
                  <div>
                    <strong>Status:</strong>{" "}
                    <em>
                      {messages[0].body} - {moment(messages[0].time).fromNow()}
                    </em>
                  </div>
                )}
              </Box>
              <Divider orientation="horizontal"></Divider>
              <Box sx={{ m: 2 }}>
                {uploadedFiles.length <= 0 ? (
                  <div>No files have been uploaded</div>
                ) : (
                  <Box>
                    <h3>Uploaded Files</h3>
                    {showImageURL !== "" && (
                      <Lightbox
                        image={showImageURL}
                        onClose={() => setShowImageURL("")}
                      ></Lightbox>
                    )}
                    {uploadedFiles.map(({ url, title }, index) => (
                      <img
                        key={`uploaded-img-${index}`}
                        src={url}
                        alt={title}
                        height="100"
                        onClick={() => setShowImageURL(url)}
                        style={{
                          cursor: "pointer",
                          marginRight: 10,
                          borderRadius: 5,
                          minHeight: 100,
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            </Grid>
            <Divider orientation="vertical" flexItem></Divider>
            <Grid item md={7} sx={{ px: 2 }}>
              <AgentScript />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {wssURL && <LogTable wssURL={wssURL} messages={messages} />}
    </Box>
  );
}

const LogTable = (props: LogTableProps) => {
  const { wssURL, messages } = props;

  if (!wssURL) {
    return <h4>Processing ...</h4>;
  } else {
    return (
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="1%"></TableCell>
                <TableCell>Event Log</TableCell>
                <TableCell align="right" width="20%">
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map((msg: Log, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row" width="1%">
                    {msg.body.startsWith("Error: ") ? (
                      <Icon sx={{ color: "red" }}>cancel</Icon>
                    ) : (
                      <Icon sx={{ color: "green" }}>check</Icon>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {msg.body}
                  </TableCell>
                  <TableCell align="right">
                    {moment(msg.time).fromNow()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }
};

const AgentScript = () => {
  return (
    <div>
      <p>
        <strong>Script for Schedulers: </strong>
        <br />
        <i>
          To best schedule your exam, it would be helpful if you could send me
          an image of your order. To do this, I need your consent for Hoag to
          send you a text message containing a secure link for you to upload an
          image of your order. The text will be sent to the mobile number you
          provide, and data and messaging rates may apply.
        </i>
      </p>
      <p>
        <strong>
          Additional Scheduler script when talking to non-patient/caregivers:{" "}
        </strong>
        <br />
        <i>
          If you are not the patient scheduling this appointment, by providing
          consent, you are acknowledging that you are the patient’s parent,
          legal guardian and/or another individual authorized by the patient to
          send the information on behalf of the patient.
        </i>
      </p>
      <p>
        <strong>Message on actual text: </strong>
        <br />
        This is Hoag Scheduling, please call [1-800-309-9729] if disconnected.
        Click [link to upload file] to upload images. Msg&Data Rates May Apply.
        Reply STOP any time to opt-out of messages.
      </p>
      <p>
        <strong>Message on Website: </strong>
        <br />
        You are now on a secure website where you can securely upload and send
        an image of your order to Hoag. If you wish to stop this process and
        schedule your appointment in another way, please notify the scheduler
        right away. By uploading an image of the order, you are acknowledging
        that you are the patient or the patient’s parent/legal guardian and/or
        another individual authorized by the patient to send the information on
        the patient’s behalf.
      </p>
    </div>
  );
};

type ConfirmationDialogProps = {
  open: boolean;
  handleOK: () => void;
  handleCancel: () => void;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Make sure you save the image before completing this request.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCancel}>Cancel</Button>
          <Button onClick={props.handleOK} autoFocus>
            Yes, Complete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FileRequest;
